<template>
  <v-container>
    <breadcrumbs class="mb-4" :items="breadcrumbs"></breadcrumbs>
    <v-sheet class="pa-8 mb-4 text-center">
      <h1>{{ $t("calendar") }}</h1>
      <v-row>
        <v-col cols="12" sm="3" align-self="center">
          <v-btn
            outlined
            class="mb-4 mt-1"
            color="grey darken-2"
            @click="setToday"
          >
            {{ $t("event-today") }}
          </v-btn>
        </v-col>
        <v-col>
          <v-btn fab text small color="grey darken-2" @click="prev">
            <v-icon small>mdi-chevron-left</v-icon>
          </v-btn>
        </v-col>
        <v-col>
          <h3 v-if="$refs.calendar">
            {{ $refs.calendar.title }}
          </h3>
        </v-col>
        <v-col>
          <v-btn fab text small color="grey darken-2" @click="next">
            <v-icon small>mdi-chevron-right</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12" sm="3" align-self="center">
          <div v-if="!eventResponse" class="font-weight-thin">
            {{ $t("loading") }}
            <v-progress-circular indeterminate class="mx-5" />
          </div>
          <div v-else-if="eventResponse.status != 200" class="font-weight-thin">
            {{ $t("communication-error") }}
          </div>
        </v-col>
      </v-row>
      <v-sheet height="560">
        <v-calendar
          ref="calendar"
          v-model="focus"
          color="accent"
          :events="events"
          :event-color="getEventColor"
          type="month"
          locale="de"
          @click:event="showEvent"
          :weekdays="weekdays"
        ></v-calendar>
        <v-dialog v-model="selectedOpen" max-width="600">
          <v-sheet>
            <v-toolbar flat :color="getEventColor(selectedEvent)" class="mb-3">
              <v-toolbar-title class="mx-auto font-weight-bold">
                {{ selectedEvent.name }}
                ({{ selectedEvent.start | formatDate }})
              </v-toolbar-title>
            </v-toolbar>
            <v-row no-gutters class="text-center mb-3">
              <v-col>
                <v-icon>mdi-clock-time-eight-outline</v-icon>
                {{ $t("event-start") }}:
                {{ selectedEvent.start | formatTime }}
              </v-col>
              <v-col v-if="selectedEvent.end">
                <v-icon>mdi-clock-time-eight</v-icon>
                {{ $t("event-end") }}:
                {{ selectedEvent.end | formatTime }}
              </v-col>
            </v-row>
            <v-row
              v-if="selectedEvent.location"
              no-gutters
              class="text-center mb-3"
            >
              <v-col class="px-5">
                <v-icon>mdi-map-marker</v-icon>
                {{ selectedEvent.location }}
              </v-col>
            </v-row>
            <p align="center" class="px-5">{{ selectedEvent.description }}</p>
            <v-row no-gutters>
              <v-btn
                text
                color="accent"
                class="mx-auto mb-2"
                @click="selectedOpen = false"
              >
                {{ $t("close") }}
              </v-btn>
            </v-row>
          </v-sheet>
        </v-dialog>
      </v-sheet>
    </v-sheet>
  </v-container>
</template>
<script>
import axios from "axios";
import Breadcrumbs from "@/components/Breadcrumbs.vue";

export default {
  name: "calendar",
  components: {
    Breadcrumbs
  },
  data: () => ({
    eventResponse: null,
    focus: "",
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    weekdays: [1, 2, 3, 4, 5, 6, 0]
  }),
  computed: {
    events: function() {
      if (!this.eventResponse || this.eventResponse.status != 200) {
        return [];
      }
      return this.eventResponse.data.data;
    },
    breadcrumbs: function() {
      return [
        {
          text: this.$t("home"),
          disabled: false,
          href: "/"
        },
        {
          text: this.$t("calendar"),
          disabled: true
        }
      ];
    }
  },
  mounted() {
    this.requestEvents();
  },
  methods: {
    requestEvents() {
      axios
        .get("https://api.bauchgefuehle-giessen.de/api/events")
        .then(response => (this.eventResponse = response))
        .catch(error => (this.eventResponse = error));
    },
    getEventColor(event) {
      if (!event.color) {
        return "accent";
      }
      return event.color;
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        setTimeout(() => (this.selectedOpen = true), 10);
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 10);
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    }
  }
};
</script>
